import React from "react";
import {
  Typography,
  Grid,
  Link
} from "@mui/material";

const Home = () => {

  return (
    <Grid container style={{ height: '100vh', }}>
      {/* <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h4">Welcome back, Aisyah (Manager)</Typography>
          </Grid> */}
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h5">Welcome back, Aisyah (Manager)</Typography>

        <iframe
          width="100%"
          height="100%"
          src="https://app.powerbi.com/view?r=eyJrIjoiNWQzNzU3MmEtMmU4Mi00MTdhLWIzYzQtNzMwYzdiY2I0MjUzIiwidCI6IjUyMThlM2IxLWM0YmEtNDcwNi05OWY5LWE2YjBkNTU0OThiNCJ9"
          allowfullscreen
          title="RTLS Dashboard"
        ></iframe>
        <Link href="https://app.powerbi.com/reportEmbed?reportId=fb718261-f333-46ff-9e9a-cc35d1177480&autoAuth=true&ctid=5218e3b1-c4ba-4706-99f9-a6b0d55498b4" target="_blank" rel="noopener noreferrer" variant="body2" style={{ float: 'right' }}>View LiveMap</Link>
      </Grid>
      <Grid>

      </Grid>

    </Grid>
  );
};

export default Home;
