import React, { useState } from 'react';
import MUIDataTable from "mui-datatables";
import { Card, Paper, Typography, Chip, Grid } from '@mui/material';
import colorConfigs from '../../configs/colorConfigs';


const LicenseAssignment = () => {

  const [data, setData] = useState(
    [
      {
        "name": "Aisyah",
        "email": "aisyah@aitssg.com",
        "licenses_purchased": 3,
        "licenses_available": 0,
        "status": "Not active"
      },
      {
        "name": "Access control",
        "licenses_purchased": 7,
        "licenses_available": 0,
        "status": "Active"
      }
    ]

  );

  const columns = [
    {
      name: "name",
      label: "name",
      options: {
        sort: true,

      },
    },
    {
      name: "licenses_purchased",
      label: "licenses_purchased",
      options: {
        sort: false,
      },
    },
    {
      name: "licenses_available",
      label: "licenses_available",
      options: {
        sort: false,
      },
    },



    {
      name: "status",
      label: "Status",
      options: {
        sort: false,
        customBodyRender: (value) => {
          let chipProps = {};
          switch (value) {
            case 'Active':
              chipProps = { label: value, color: "success" };
              break;
            case 'Not active':
              chipProps = { label: value, variant: "filled", disabled: 'true' };
              break;

            default:
              chipProps = { label: value };
          }
          return <Chip {...chipProps} />;
        }
      },
    },
  ];



  const options = {
    filter: true,
    search: true,
    pagination: true,
    selectableRows: "none", // or "multiple" for checkboxes
    serverSide: false,
    download: false,
    print: false,
    viewColumns: false,
    responsive: "standard"
  };

  return (
    <Grid container spacing={3} >

      <Grid item container justifyContent="space-around" alignItems="center" spacing={3}>
        <Grid item xs={12} sm={6} md={4} >
          <Card style={{ width: '100%', height: '400px', padding: '20px' }}>
            <Paper elevation={2} style={{ height: '70%', backgroundColor: colorConfigs.colorCode.tertiery, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant="h5">20 out 100</Typography>
            </Paper>
            <Typography variant="h6" align="center" style={{ marginTop: '10px', textAlign: 'center' }}>User roles</Typography>

            <Typography variant="body1" align="center">User = 20</Typography>
            <Typography variant="body1" align="center">Administrators = 2</Typography>
            <Typography variant="body1" align="center">User groups = 50</Typography>
          </Card>

        </Grid>
        <Grid item xs={12} sm={6} md={4} >
          <Card style={{ width: '100%', height: '400px', padding: '20px' }}>
            <Paper elevation={2} style={{ height: '70%', backgroundColor: colorConfigs.colorCode.tertiery, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant="h5">20 out 100</Typography>
            </Paper>
            <Typography variant="h6" align="center" style={{ marginTop: '10px', textAlign: 'center' }}>Individual licenses</Typography>
            <Typography variant="body1" align="center">Assigned Lincenses:80</Typography>
            <Typography variant="body1" align="center">Available :100</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} >
          <Card style={{ width: '100%', height: '400px', padding: '20px' }}>
            <Paper elevation={2} style={{ height: '70%', backgroundColor: colorConfigs.colorCode.tertiery, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant="h5">+20%</Typography>
            </Paper>
            <Typography variant="h6" align="center" style={{ marginTop: '10px', textAlign: 'center' }}>Project In progress</Typography>
            <Typography variant="body1" align="center">(requires configuration)</Typography>
          </Card>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} sm={12}>
        <MUIDataTable
          data={data}
          columns={columns}
          options={options}
        />
      </Grid>
    </Grid>
  );
};

export default LicenseAssignment;
