const sizeConfigs = {
  sidebar: {
    width: "15rem",
    xsWidth: "3.5rem"
  }
};



export default sizeConfigs;
