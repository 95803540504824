import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import DataServices from "../Services/DataServices";
import {
  Grid,
  Autocomplete,
  Typography,
  TextField,
  Toolbar,
} from "@mui/material";
import BackDropLoading from "../Components/Loading";
import AppText from "../Utils/AppText";
import { showToast } from "../Components/ToastBox";

const MeetingRoomBookingHistory = () => {
  const [userList, setUserList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [orgDropDownOptions, setOrgDropDownOptions] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  // useEffect(() => {
  //   const fetchOrgDropdownData = async () => {
  //     try {
  //       setIsLoading(true);
  //       const response = await DataServices.getOrgDropDownDetailsApi();
  //       if (response.statusCode == 200) {
  //         try {
  //           var dummy = [...response.response.body];
  //           if (orgDropDownOptions < 1) {
  //             setOrgDropDownOptions(dummy);
  //           }
  //         } catch (error) {
  //           showToast("Something went wrong ", "error");
  //         }
  //       } else {
  //         showToast(response.statusDescription, "error");
  //       }
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };
  //   fetchOrgDropdownData();
  // }, []);

  useEffect(() => {
    const meetingRoomBookingHistoryCall = async () => {
      setIsLoading(true);

      const response = await DataServices.meetingRoomBookingHistory();
      if (response && response?.statusCode == 200) {
        setIsLoading(false);
        setUserList(response.response.body);
      } else {
        setIsLoading(false);
        console.log(response);
      }
    };

    meetingRoomBookingHistoryCall(selectedOrganization ?? "");
  }, []);

  const columns = [
    {
      name: "roomName",
      label: "Meeting Room Name",
      options: {
        sort: true,
        setCellHeaderProps: (value) => ({
          align: "center",
        }),
      },
    },
    {
      name: "startTime",
      label: "Start Date and Time",
      options: {
        sort: true,

        setCellHeaderProps: (value) => ({
          align: "center",
        }),
      },
    },
    {
      name: "endTime",
      label: "End Date and Time",
      options: {
        sort: true,
        setCellHeaderProps: (value) => ({
          align: "center",
        }),
      },
    },
    // {
    //   name: "bookingId",
    //   label: "Meeting Room Id",
    //   options: {
    //     sort: true,
    //     setCellHeaderProps: (value) => ({
    //       align: "center",
    //     }),
    //   },
    // },
  ];
  const options = {
    download: false,
    print: false,
    onRowClick: false,
    filter: false,
    viewColumns: false,
    selectableRows: "none",
    responsive: "standard",
  };

  const organization = [
    { label: "AITS" },
    { label: "Organization B" },
    { label: "Organization C" },
    { label: "Organization D" },
  ];

  return (
    <Grid>
      <Grid>
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">
            {AppText.mRCmTxt.meetingRoomBookinHistory}
          </Typography>
        </Toolbar>
      </Grid>
      <Grid container spacing={0} direction="column">
        {/* <Grid item xs={12}>
          <Autocomplete
            disablePortal
            id="OrganizationName"
            options={orgDropDownOptions}
            sx={{ width: 250, float: "left", marginBottom: "20px" }}
            onChange={(event, newValue) =>
              setSelectedOrganization(newValue?.value)
            }
            renderInput={(params) => (
              <TextField {...params} label="Organization Name" />
            )}
          />
        </Grid> */}

        <Grid item xs={12}>
          <MUIDataTable
            title={<div></div>}
            data={userList}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
      {isLoading && <BackDropLoading open={isLoading} />}
    </Grid>
  );
};

export default MeetingRoomBookingHistory;
