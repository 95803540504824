import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";

import {
  Grid,
  Autocomplete,
  Typography,
  TextField,
  Toolbar,
} from "@mui/material";
import moment from "moment";
import DataServices from "../Services/DataServices";
import { showToast } from "../Components/ToastBox";
import BackDropLoading from "../Components/Loading";

const WorkStationBookingHistory = () => {
  const [data, setData] = useState([]);

  const [orgDropDownOptions, setOrgDropDownOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  // useEffect(() => {
  //   fetchOrgDropdownData();
  // }, []);
  // const fetchOrgDropdownData = async () => {
  //   try {
  //     setIsLoading(true);
  //     const response = await DataServices.getOrgDropDownDetailsApi();
  //     if (response.statusCode == 200) {
  //       try {
  //         var dummy = [...response.response.body];
  //         if (orgDropDownOptions < 1) {
  //           setOrgDropDownOptions(dummy);
  //         }
  //       } catch (error) {
  //         showToast("Something went wrong ", "error");
  //       }
  //     } else {
  //       showToast(response.statusDescription, "error");
  //     }
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  useEffect(() => {
    fetchWorkStationHistoryCall();
  }, []);

  const fetchWorkStationHistoryCall = async (data) => {
    try {
      setIsLoading(true);
      const response = await DataServices.getWorkstationHistoryApi(data);
      if (response?.statusCode == 200) {
        try {
          setData(response.response.body);
        } catch (error) {
          showToast("Something went wrong ", "error");
        }
      } else {
        showToast(response?.statusDescription, "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      name: "deskId",
      label: "HotDeskId",
      options: {
        sort: true,
        // setCellHeaderProps: (value) => ({
        //   align: "center",
        // }),
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      name: "organizerEmail",
      label: "Organizer Mail",
      options: {
        sort: true,
        // setCellHeaderProps: (value) => ({
        //   align: "center",
        // }),
        // customBodyRender: (value) => (
        //   <div style={{ textAlign: "center" }}>{value}</div>
        // ),
      },
    },

    {
      name: "location",
      label: "Location",
      options: {
        sort: true,
        // setCellHeaderProps: (value) => ({
        //   align: "center",
        // }),
        customBodyRender: (value) => <div>SG</div>,
      },
    },

    {
      name: "bookingDate",
      label: "Booking Date",

      options: {
        sort: true,
        // customBodyRender: (value) => (
        //   <div style={{ textAlign: "center" }}>
        //     {moment(value).format("MM/DD/YYYY")}
        //   </div>
        // ),
      },
    },
    // {
    //   name: "SeatNo",
    //   label: "Seat No",
    //   options: {
    //     sort: true,
    //     filtetType: "multiselect",
    //     customBodyRender: (value) => (
    //       <div style={{ textAlign: "center" }}>{value}</div>
    //     ),
    //   },
    // },

    // {
    //   name: "ProjectId",
    //   label: "Project Id",
    //   options: {
    //     sort: true,
    //     filtetType: "multiselect",
    //     customBodyRender: (value) => (
    //       <div style={{ textAlign: "center" }}>{value}</div>
    //     ),
    //   },
    // },
  ];
  const options = {
    download: false,
    print: false,
    onRowClick: false,
    filter: false,
    viewColumns: false,
    selectableRows: "none",
    responsive: "standard",
  };

  return (
    <Grid>
      <Grid>
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">Workstation Booking History</Typography>
        </Toolbar>
      </Grid>
      <Grid container spacing={0} direction="column">
        {/* <Grid item xs={12}>
          <Autocomplete
            disablePortal
            id="OrganizationName"
            options={orgDropDownOptions}
            sx={{ width: 250, float: "left", marginBottom: "20px" }}
            renderInput={(params) => (
              <TextField {...params} label="Organization Name" />
            )}
          />
        </Grid> */}

        <Grid item xs={12}>
          <MUIDataTable data={data} columns={columns} options={options} />
        </Grid>
      </Grid>
      {isLoading && <BackDropLoading open={isLoading} />}
    </Grid>
  );
};

export default WorkStationBookingHistory;
