import React from "react";
import { Card, Drawer, List, Stack, Toolbar, Typography ,Box} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import colorConfigs from "../configs/colorConfigs";
import sizeConfigs from "../configs/sizeConfigs";
import appRoutes from "../Routes/appRoutes";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarCollapse";
import IconButton from "@mui/material/IconButton";
import logo from "../Assets/logo.png";
import AppText from "../Utils/AppText";

const Sidebar = (props) => {

  const handleSidebarOpen = () => {
    if (props.onMouseEnter) {
      props.onMouseEnter();
    }
  };

  const handleSidebarClose = () => {
    if (props.onMouseLeave) {
      props.onMouseLeave();
    }
  };
  return (
    <Card variant="elevation" >
      <Drawer
        variant="permanent"
        onMouseEnter={handleSidebarOpen}
        onMouseLeave={handleSidebarClose}
        sx={{
          width: props.open
            ? sizeConfigs.sidebar.width
            : sizeConfigs.sidebar.xsWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: props.open
              ? sizeConfigs.sidebar.width
              : sizeConfigs.sidebar.xsWidth,
            boxSizing: "border-box",
            borderRight: "0px",
            backgroundColor: colorConfigs.sidebar.bg,
            color: colorConfigs.sidebar.color,
          },
        }}>
          <Toolbar />
          <Box sx={{ overflow: 'auto' }}>
        <List >
         
          {appRoutes.map((route, index) =>
            route.sidebarProps ? (
              route.child ? (
                <SidebarItemCollapse item={route} key={index} showText={props.open} />
              ) : (
                <SidebarItem item={route} key={index} showText={props.open}/>
              )
            ) : null
          )}
        </List>
        </Box>
      </Drawer>
    </Card>
  );
};

export default Sidebar;
