import React, { useState, useEffect } from "react";
import { ListItemButton, ListItemIcon ,Tooltip,Typography} from "@mui/material";
import { Link } from "react-router-dom";
import colorConfigs from "../configs/colorConfigs";

import { useLocation } from "react-router-dom";

const SidebarItem = ({ item,showText }) => {
  let location = useLocation();
  const [pathName, setPathName] = useState(location.pathname);
  useEffect(() => {
    //console.log('lll', location);
    setPathName(location.pathname);
  }, [location]);

    return (
        item.sidebarProps && item.path ? (
          <ListItemButton
            component={Link}
            to={item.path}
            sx={{
              "&: hover": {
                backgroundColor: colorConfigs.sidebar.hoverBg,
                color: colorConfigs.colorCode.black,
              
              },
              backgroundColor:  pathName === item.path ?  colorConfigs.sidebar.activeBg : `none` ,
              // '& .MuiListItemButton-root': {
              //   fontFamily: 'Inter, sans-serif', 
              // },
             //  paddingY: "12px",
              // paddingX: "24px",
              
              color:pathName === item.path ?  colorConfigs.sidebar.color : colorConfigs.colorCode.black,
            }}
          >
                <Tooltip title={item.sidebarProps.displayText} sx={{'& .MuiTooltip-tooltip': {
      fontFamily: 'Inter, sans-serif',
    },}}>
                  <ListItemIcon sx={{
                    color: pathName === item.path ?  colorConfigs.sidebar.color : colorConfigs.colorCode.black,
                    margin: `-5px`,
                  }}>
                    {item.sidebarProps.icon && item.sidebarProps.icon}
                  </ListItemIcon>
                </Tooltip>
              {showText == true &&
                <Typography variant="caption" >
                {item.sidebarProps.displayText}
                </Typography>
}
                {/* {item.sidebarProps.displayText} */}
          </ListItemButton>
        ) : null
      );
}

export default SidebarItem;
