import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Card,
  CardContent,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  Avatar,
  IconButton,
  Stack,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logout from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import colorConfigs from "../configs/colorConfigs";
import sizeConfigs from "../configs/sizeConfigs";
import logo from "../Assets/logo.png";
import { useTheme } from "@mui/material";
import AppText from "../Utils/AppText";

const Topbar = (props) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = async () => {
    sessionStorage.removeItem("loggedInUserDetails");
    navigate("/login");
  };

  const navigateToDashboard = () => {
    navigate("/dashboard");
    navigate(0);
  };

  return (
    <Card variant="elevation">
      <CardContent>
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: colorConfigs.topbar.bg,
          }}
        >
          <Toolbar sx={{ justifyContent: "space-between" }}>
            {/* <Typography variant="h5" style={{ color: "#000000"}}>
            {props && !props.open &&(
              <IconButton sx={{color: colorConfigs.topbar.color}} onClick={props.changeEvent}>
                <MenuIcon />
              </IconButton>
            )}            
          </Typography>
             */}
            <Stack direction="row" alignItems="center">
              <IconButton onClick={props.changeEvent}>
                <MenuIcon />
              </IconButton>
              <img
                src={logo}
                width={60}
                style={{ paddingRight: `5px` }}
                alt="chope"
              />
              <Typography variant="h6" style={{ color: "black" }}>
                {AppText.chopeAi}
              </Typography>
            </Stack>

            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar>
                <AccountCircleIcon />
              </Avatar>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
              }}
            >
              <MenuItem onClick={logOut}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
      </CardContent>
    </Card>
  );
};

export default Topbar;

/*
 
*/
