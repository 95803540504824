import React, { useState, useCallback, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import AddNewOrganization from "./AddNewOrganization";
import AddIcon from "@mui/icons-material/ControlPoint";
import BackDropLoading from "../Components/Loading";

import {
  Grid,
  Typography,
  Chip,
  Button,
  Toolbar,
  TableCell,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { showToast } from "../Components/ToastBox";
import { useNavigate } from "react-router-dom";
import DataServices from "../Services/DataServices";
import EditOrganizationDetails from "./EditOrganizationDetails";

const OrganizationOverview = () => {
  const navigate = useNavigate();
  const [OrganizationList, setOrganizationList] = useState([
    // {
    //   OrganizationName: "AITS",
    //   OrgID: "2798",
    //   AssignedUser: "150",
    //   ProductType: "Locker Booking",
    //   SubscriptionStatus: "Active",
    // },
    // {
    //   OrganizationName: "AITS",
    //   OrgID: "2798",
    //   AssignedUser: "150",
    //   ProductType: "Room Booking",
    //   SubscriptionStatus: "InActive",
    // },
    // {
    //   OrganizationName: "AITS",
    //   OrgID: "2798",
    //   AssignedUser: "150",
    //   ProductType: "Desk Booking",
    //   SubscriptionStatus: "Expiring",
    // },
    // {
    //   OrganizationName: "AITS",
    //   OrgID: "2798",
    //   AssignedUser: "150",
    //   ProductType: "Locker Booking",
    //   SubscriptionStatus: "Active",
    // },
  ]);

  const editIcon = <EditIcon color="primary" />;
  const viewIcon = <VisibilityIcon color="action" />;
  const [isLoading, setIsLoading] = useState(false);
  const [orgDetails, setOrgDetails] = useState(false);

  useEffect(() => {
    getAllOrganizationsDetailsCall();
  }, []);
  const columns = [
    {
      name: "organizationName",
      label: "Organization Name",
      options: {
        sort: true,
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      name: "organizationCode",
      label: "Org ID",
      options: {
        sort: true,
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      name: "emailId",
      label: "Email ID",
      options: {
        sort: true,
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },

    {
      name: "status",
      label: "Subscription Status",
      options: {
        sort: true,

        customBodyRender: (value, tableMeta, updateValue) => {
          let color, backgroundColor;

          switch (value?.toLowerCase()) {
            case "active":
              color = "#fff";
              backgroundColor = "#545F71";
              break;
            case "inactive":
              color = "#000";
              backgroundColor = "lightgrey";
              break;
            case "expiring":
              color = "#fff";
              backgroundColor = "#D4AF37";
              break;
            case "pending":
              color = "orange";
              backgroundColor = "lightyellow";
              break;
            default:
              color = "#ffffff";
              backgroundColor = "#ffffff";
              break;
          }
          return (
            <div style={{ textAlign: "center" }}>
              {value ? (
                <Chip
                  label={value}
                  style={{
                    backgroundColor,
                    color,
                  }}
                />
              ) : null}
            </div>
          );
        },
      },
    },
    {
      name: "Actions",
      options: {
        sort: true,
        customBodyRenderLite: (dataIndex) => (
          <div>
            <span
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => handleEdit(OrganizationList[dataIndex])}
            >
              {editIcon}
            </span>
            {/* <span
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => handleView(tableMeta.rowData)}
            >
              {viewIcon}
            </span> */}
          </div>
        ),
      },
    },
  ];

  const getAllOrganizationsDetailsCall = async (data) => {
    try {
      setIsLoading(true);
      const response = await DataServices.getOrganizationsDetilsApi(data);
      if (response?.statusCode == 200) {
        try {
          setOrganizationList(response.response.body);
        } catch (error) {
          showToast("Something went wrong ", "error");
        }
      } else {
        showToast(response?.statusDescription, "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const addNewOrganizationCall = async (data) => {
    try {
      setIsLoading(true);
      const response = await DataServices.addNewOrganizationApi(data);
      if (response?.statusCode == 200) {
        try {
          await getAllOrganizationsDetailsCall();
          showToast(response.response.body.message, "success");
        } catch (error) {
          showToast("Something went wrong ", "error");
        }
      } else {
        showToast(response.statusDescription, "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const editOrganizationCall = async (data) => {
    try {
      setIsLoading(true);
      const response = await DataServices.editOrganizationApi(data);
      if (response?.statusCode == 200) {
        try {
          await getAllOrganizationsDetailsCall();
          showToast(response.response.body.message, "success");
        } catch (error) {
          showToast("Something went wrong ", "error");
        }
      } else {
        showToast(response.statusDescription, "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const options = {
    download: false,
    print: false,
    onRowClick: false,
    viewColumns: false,
    selectableRows: "none",
    responsive: "standard",
    filterType: true,
  };

  const handleEdit = (rowData) => {
    setOrgDetails(rowData);
    setIsEditModalOpen(true);
    // Handle edit action, rowData contains the data for the clicked row
    console.log("Edit clicked for row:", rowData);
  };

  const handleView = (rowData) => {
    // Handle view action, rowData contains the data for the clicked row
    console.log("View clicked for row:", rowData);
  };

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const openAddModal = () => {
    setIsAddModalOpen(true);
  };

  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };

  const handleAddOrganization = useCallback(
    async (newOrganizationData) => {
      try {
        setIsAddModalOpen(false);
        console.log("New Organization Data:", newOrganizationData);
        await addNewOrganizationCall(newOrganizationData);
      } catch (error) {
        console.error("Error adding organization:", error);
        // Handle any errors (e.g., show a notification)
      }
    },
    [closeAddModal] // Dependencies array, add other dependencies if needed
  );

  const handleEditOrganization = useCallback(
    async (editOrganizationData) => {
      try {
        setIsEditModalOpen(false);
        console.log("Edit Organization Data:", editOrganizationData);
        await editOrganizationCall(editOrganizationData);
      } catch (error) {
        console.error("Error Editting organization:", error);
        // Handle any errors (e.g., show a notification)
      }
    },
    [] // Dependencies array, add other dependencies if needed
  );

  return (
    <Grid>
      <Grid>
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">Organization Overview</Typography>
        </Toolbar>
      </Grid>
      <Grid container spacing={0} direction="column">
        <Grid item xs={12}>
          <Button
            variant="outlined"
            style={{
              float: "right",
              color: "#FFFFFF",
              backgroundColor: "#545F71",
              marginBottom: "20px",
            }}
            onClick={openAddModal}
            startIcon={<AddIcon />}
          >
            Add New Organization
          </Button>
        </Grid>

        {isAddModalOpen && (
          <AddNewOrganization
            onSave={handleAddOrganization}
            onCancel={closeAddModal}
          />
        )}

        {isEditModalOpen && (
          <EditOrganizationDetails
            open={isEditModalOpen}
            onSave={handleEditOrganization}
            onCancel={() => {
              setIsEditModalOpen(false);
            }}
            orgDetails={orgDetails}
          />
        )}
        <Grid item xs={12}>
          <MUIDataTable
            title={<div> </div>}
            data={OrganizationList}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
      {isLoading && <BackDropLoading open={isLoading} />}
    </Grid>
  );
};

export default OrganizationOverview;
