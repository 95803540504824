import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  Grid,
  Button,
  TextField,
  CardContent,
  Divider,
  Toolbar,
  Autocomplete,
} from "@mui/material";
import Dropzone from "react-dropzone";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import DataServices from "../../Services/DataServices";
import { showToast } from "../../Components/ToastBox";
import BackDropLoading from "../../Components/Loading";
import { useNavigate } from "react-router-dom";

// Define Yup validation schema

const Overview = () => {
  const navigate = useNavigate();
  const [orgDropDownOptions, setOrgDropDownOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object({
    userCode: Yup.string().required("User ID is required"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    mobileNo: Yup.string()
      .matches(/^[0-9]{10}$/, "Must be a 10-digit number")
      .required("Mobile number is required"),
    organizationCode: Yup.string().required("Organization is required"),
  });
  const initialValues = {
    userCode: "",
    firstName: "",
    lastName: "",
    email: "",
    mobileNo: "",
    organizationCode: "",
  };

  const handleSaveChanges = async (values) => {
    await addUserCall(values);
  };

  const handleCancel = () => {
    console.log("Form canceled");
  };

  useEffect(() => {
    fetchOrgDropdownData();
  }, []);
  const fetchOrgDropdownData = async () => {
    try {
      setIsLoading(true);
      const response = await DataServices.getOrgDropDownDetailsApi();
      if (response.statusCode == 200) {
        try {
          var dummy = [...response.response.body];
          if (orgDropDownOptions < 1) {
            setOrgDropDownOptions(dummy);
          }
        } catch (error) {
          showToast("Something went wrong ", "error");
        }
      } else {
        showToast(response.statusDescription, "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const addUserCall = async (data) => {
    const updatedData = { ...data, role: "USR" };
    try {
      setIsLoading(true);
      const response = await DataServices.addNewUserApi(updatedData);
      if (response?.statusCode == 200) {
        try {
          showToast("User created successfully", "success");
          navigate("/users/Userlist");
        } catch (error) {
          showToast("Something went wrong ", "error");
        }
      } else {
        showToast(response.statusDescription, "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Toolbar>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h5">Create New User</Typography>
          </Grid>
        </Toolbar>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          md={5}
          sm={4}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Grid>
            <Typography variant="h6" ml={3}>
              Profile
            </Typography>
            <Typography variant="body2" ml={3}>
              Upload Photo and Enter personal details
            </Typography>

            <Grid>
              <Dropzone onDrop={(acceptedFiles) => console.log(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />

                      <Typography
                        style={{
                          border: "dotted",
                          borderWidth: "medium",
                          margin: 30,
                          width: "220px",
                          height: "200px",
                          textAlign: "center",
                          padding: 60,
                        }}
                      >
                        Drag 'n' drop your Picture
                      </Typography>
                    </div>
                  </section>
                )}
              </Dropzone>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={7} sm={8}>
          <Card>
            <CardContent>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => handleSaveChanges(values)}
              >
                {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <TextField
                      name="userCode"
                      label="User Id"
                      value={values.userCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      margin="normal"
                      error={touched.userCode && !!errors.userCode}
                      helperText={touched.userCode && errors.userCode}
                    />

                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          name="firstName"
                          label="First Name"
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          margin="normal"
                          error={touched.firstName && !!errors.firstName}
                          helperText={touched.firstName && errors.firstName}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="lastName"
                          label="Last Name"
                          value={values.lastName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                          error={touched.lastName && !!errors.lastName}
                          helperText={touched.lastName && errors.lastName}
                        />
                      </Grid>
                    </Grid>

                    <TextField
                      name="email"
                      label="Email Address"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      margin="normal"
                      error={touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                    />

                    <TextField
                      name="mobileNo"
                      label="Mobile Number"
                      value={values?.mobileNo}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      margin="normal"
                      error={touched.mobileNo && !!errors.mobileNo}
                      helperText={touched.mobileNo && errors.mobileNo}
                    />

                    <Autocomplete
                      disableClearable
                      id="organizationCode"
                      options={orgDropDownOptions}
                      getOptionLabel={(option) => option.label || ""}
                      value={
                        orgDropDownOptions.find(
                          (option) => option.value === values?.organizationCode
                        ) || null
                      }
                      onChange={(e, newValue) => {
                        setFieldValue("organizationCode", newValue?.value); // Update Formik field value
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="normal"
                          label={"Organization"}
                          onBlur={handleBlur}
                          error={
                            touched.organizationCode &&
                            !!errors.organizationCode
                          }
                          helperText={
                            touched.organizationCode && errors.organizationCode
                          }
                        />
                      )}
                    />

                    <Divider />
                    <Grid style={{ marginTop: "20px", textAlign: "right" }}>
                      <Button type="submit" variant="contained">
                        Save Changes
                      </Button>
                      <Button
                        variant="outlined"
                        type="reset"
                        onClick={handleCancel}
                        style={{ marginLeft: "10px" }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {isLoading && <BackDropLoading open={isLoading} />}
    </Grid>
  );
};

export default Overview;
