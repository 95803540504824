import { colors } from "@mui/material";

const colorConfigs = {
  sidebar: {
    bg: colors.grey[100],
    color: '#FFFFFF',
    hoverBg:'#47546',
    activeBg: '#7F56D9',
  },
  colorCode: {
    primary: `#DF2626`,
    secondary: "#C7C7C7",
    tertiery:  "#EEF1F4",
    voilet:'#6941C6',
    black:'#000000',
    Grey:'#D0D5DD'
  },
  topbar: {
    bg: colors.grey[100],
    color: "#000"
  },
  mainBg: `#FFFFFF`,
};

export default colorConfigs;