import { createTheme } from '@mui/material';



export const lightTheme= createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#8D5BE9',
      
    },
    secondary: {
      main: '#bbdefb',
      
    },
  },
  // MuiTooltip: {
  //   styleOverrides: {
  //     tooltip: {
  //       fontFamily: 'Inter', 
  //       color:'#fffff',
  //       fontSize: '2.25rem',
  //       lineHeight: 1.375
  //     },
  //   },
  // },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        fontFamily: 'Inter, sans-serif',
        // other styles you may want to apply to the ListItemButton
      },
    },
  },
  typography: {
    fontFamily: 'Inter',
    // Other typography configurations if needed
    button: {
      fontWeight: 600
    },
    body1: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.66
    },
    body2: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.57
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.75
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.57
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: 600,
      letterSpacing: '0.5px',
      lineHeight: 2.5,
      textTransform: 'uppercase'
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 600,
      lineHeight: 1.66
    },
    h1: {
      fontWeight: 600,
      fontSize: '3.5rem',
      lineHeight: 1.375
    },
    h2: {
      fontWeight: 600,
      fontSize: '3rem',
      lineHeight: 1.375
    },
    h3: {
      fontWeight: 600,
      fontSize: '2.25rem',
      lineHeight: 1.375
    },
    h4: {
      fontWeight: 600,
      fontSize: '2rem',
      lineHeight: 1.375
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.45rem',
      lineHeight: 1.375
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.1rem',
      lineHeight: 1.375
    }
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'outlined', 
        labelStyle: {
          fontFamily: 'Inter', 
         
        },
      },
      styleOverrides: {
        root: {
          fontFamily: 'Inter', 
          
        },
        input: {
          '&::placeholder': {
            fontFamily: 'Inter', // Applying Inter font family to placeholder text
            // Other placeholder text style overrides
            // ...
          },
        },
       
      },
    },
    MUIDataTable: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter', 
          fontSize: '0.75rem',
          fontWeight: 'bold',
          lineHeight: '1.5 '
          
        },
        headCell: {
          fontSize: '0.75rem',
          fontWeight: 'bold',
          lineHeight: '1.5 '
        },
        bodyCell: {
          fontSize: '0.75rem',
          fontWeight: 'bold',
          lineHeight: '1.5'
        },
      },
    },
    MuiChip: {
      defaultProps: {
        
        labelStyle: {
          fontFamily: 'Inter', // Applying Inter font family to chip label
          
        },
      },
      styleOverrides: {
        root: {
          fontFamily: 'Inter', 
         
        },
        
      },
    },
     MuiButton: {
    
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontFamily: 'Inter', 
        },
        sizeSmall: {
          padding: '6px 16px',
        },
        sizeMedium: {
          padding: '8px 20px'
        },
        sizeLarge: {
          padding: '11px 24px'
        },
        textSizeSmall: {
          padding: '7px 12px'
        },
        textSizeMedium: {
          padding: '9px 16px'
        },
        textSizeLarge: {
          padding: '12px 16px'
        }
      }
    },
    // MuiCardContent: {
    //   styleOverrides: {
    //     root: {
         
    //     }
    //   }
    // },
    // MuiCardHeader: {
    //   defaultProps: {
    //     titleTypographyProps: {
    //       variant: 'h6'
    //     },
    //     subheaderTypographyProps: {
    //       variant: 'body2'
    //     }
    //   },
    //   styleOverrides: {
    //     root: {
          
    //     }
    //   }
    // },
    
  }
})
  