import React, { useState,useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import colorConfigs from "../configs/colorConfigs";
import sizeConfigs from "../configs/sizeConfigs";
import Sidebar from "../SidebarController/Sidebar";
import Topbar from "../SidebarController/Topbar";

const MainLayout = () => {
  const [open, setOpen] = useState(false);
  const [hovered, setHovered] = useState(false);
  

  useEffect(() => {
    const sidebarPosition = sessionStorage.getItem("sidebarPosition");
    setOpen(sidebarPosition === "true");
  }, []);

  const handleToggle = () => {
    const newState = !open;
    sessionStorage.setItem("sidebarPosition", newState);
    setOpen(newState);
  };

  const handleMouseEnter = () => {
    if (!open) {
      setHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (!open) {
      setHovered(false);
    }
  };

  const handleCloseSidebar = () => {
    if (!hovered) {
      setOpen(false);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Topbar open={open} changeEvent={handleToggle} />
      <Box
        component="nav"
        sx={{
          width: open ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.xsWidth,
          flexShrink: 0,
          
        }}>
        <Sidebar  open={open || hovered} changeEvent={handleToggle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: `calc(100% - ${
            open ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.xsWidth
          })`,
          minHeight: "100vh",
          background: colorConfigs.mainBg,
     
        }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
