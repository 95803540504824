import React, { useState } from 'react';
import MUIDataTable from "mui-datatables";

import {
  Grid,
  Autocomplete,
  Typography,
  TextField,
  Toolbar,
} from '@mui/material';




const LockerBookingHistory= () => {
    const [userList, setUserList] = useState([{ StartDateandTime: "26-Oct-23 13:30:00", EndDateandTime: "26-Oct-23 16:30:00", LockerId: "L2001",UnitNumber: "08-13",ProjectId:"P23001" },
    { StartDateandTime: "26-Oct-23 13:30:00", EndDateandTime: "26-Oct-23 16:30:00", LockerId: "L2001",UnitNumber: "08-13",ProjectId:"P23001" },
    {StartDateandTime: "26-Oct-23 13:30:00", EndDateandTime: "26-Oct-23 16:30:00", LockerId: "L2001",UnitNumber: "08-13",ProjectId:"P23001" },
    {StartDateandTime: "26-Oct-23 13:30:00", EndDateandTime: "26-Oct-23 16:30:00", LockerId: "L2001",UnitNumber: "08-13",ProjectId:"P23001"},]);
    
const columns = [
      {
       name: "StartDateandTime",
       label: "Start Date and Time",
       options: {
        sort: true,
       
        setCellHeaderProps: (value) => ({
          align: "center",
        }),
       }
      },
      {
       name: "EndDateandTime",
       label: "End Date and Time",
       options: {
       sort: true,
       setCellHeaderProps: (value) => ({
        align: "center",
      }),
       }
      },
      {
       name: "LockerId",
       label: "Locker Id",
       options: {
      sort: true,
      setCellHeaderProps: (value) => ({
        align: "center",
      }),
       }
      },
      {
       name: "UnitNumber",
       label: "Unit Number",
       options: {
        sort: true,
        setCellHeaderProps: (value) => ({
          align: "center",
        }),
       }
      },
     
       {
        name: 'ProjectId',
        label: 'Project Id',
        options: {
          sort: true,
          filtetType: 'multiselect',
          setCellHeaderProps: (value) => ({
            align: "center",
          }),
        },
       },
       
     ];
     const options = {
     download : false,
     print : false,
     onRowClick : false,
     filter : false,
     viewColumns : false, 
     selectableRows : "none",
     responsive: "standard",
    };  

    const organization = [
        { label: 'AITS'},
        { label: 'Organization B' },
        { label: 'Organization C' },
        { label: 'Organization D' },]
  
 return(
  <Grid><Grid><Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
  <Typography variant="h5">Locker Booking History</Typography>
  
    </Toolbar>
    </Grid>
<Grid container 
spacing={0}
direction = "column"
>
  <Grid item xs={12}>
  <Autocomplete
      disablePortal
      id="OrganizationName"
      options={organization}
      sx={{ width: 250,
        float: 'left',marginBottom: '20px' }}
      renderInput={(params) => <TextField {...params} label="Organization Name" />}
    />
  </Grid>
  
                <Grid item xs={12}>
              <MUIDataTable
                title={
                  <div>
                  </div>
                }
                data={userList}
                columns={columns}
                options={options}
              />
              </Grid>
              
              </Grid>
          </Grid>
);

};

export default LockerBookingHistory;