import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Toolbar,
  Grid,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SettingsIcon from "@mui/icons-material/Settings";
import DownloadIcon from "@mui/icons-material/CloudDownloadOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { BarChart, PieChart } from "@mui/x-charts";
import workstationIcon from "../Assets/Avatar.png";
import AppText from "../Utils/AppText";

const MeetingAndworkstationaBookingOccupancy = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const bardata = [
    {
      data: [
        200, 400, 600, 500, 400, 500, 670, 230, 360, 200, 450, 120,
      ],
      stack: "A",
      label: "Series A1",
    },
    {
      data: [
        400, 300, 100, 500, 800, 500, 670, 230, 360, 200, 450, 120,
      ],
      stack: "A",
      label: "Series A2",
    },
    {
      data: [
        400, 200, 500, 400, 100, 500, 670, 230, 360, 200, 450, 120,
      ],
      stack: "A",
      label: "Series B1",
    },


  ];
  const piechartData =[
    {
      data: [
        { id: 0, value: 10, label: "series A" },
        { id: 1, value: 15, label: "series B" },
        { id: 2, value: 20, label: "series C" },
      ],
    },
  ]
  const month = [ "jan","feb","mar","apr","may","jun","jul","aug","sep","oct","nov","dec",];
  return (
    
    <Grid container >
        <Grid item xs={12}>
        <Toolbar style={{ display: "flex", justifyContent: "flex-end" }}>
          <Grid container >
          {/* <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h4">Welcome back, Aisyah (Manager)</Typography>
          </Grid> */}
          <Grid item xs={12} sm={12} md={12} sx={{paddingTop:2, paddingBottom:2}}>
            <Grid container justifyContent="flex-end" spacing={1}>
              <Grid item>
                <IconButton onClick={handleMenuClick}>
                  <SearchIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  startIcon={<SettingsIcon />}
                >
                  {AppText.buttonText.customize}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  startIcon={<DownloadIcon />}
                >
                {AppText.buttonText.export}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          </Grid>
          
          
        </Toolbar>
      </Grid>





    <Grid container >
        <Grid item container xs={12} sm={12} md={12} spacing={3}>
        <Grid item xs={12} sm={6} md={6} >
            <Card>
              <CardHeader
                avatar={
                  <img
                    src={workstationIcon}
                    width={60}
                    style={{ padding: `10px` }}
                    alt="workstation"
                  />
                }
                title={
                  <Typography variant="h5">Workstation usage breakdown</Typography>
                }
                subheader="Keep track of workstations and their occupancy."
                action={
                  <IconButton onClick={handleMenuClick}>
                    <MoreVertIcon />
                  </IconButton>
                }
              />
              <Divider />
              <CardContent>
                <BarChart
                  series={bardata}
                  width={600}
                  height={350}
                  xAxis={[
                    {
                      scaleType: "band",
                      data:month,
                      categoryGapRatio: "0.3",
                      barGapRatio: "0.3",
                },
              ]}
            />
                <Divider />
                <Grid item xs={12} style={{marginTop:'3%'}}>
        <Grid container justifyContent="flex-end">
          <Button variant="outlined">
            View Full Report1
          </Button>
        </Grid>
      </Grid>
              </CardContent>
            </Card>
</Grid>



<Grid item  xs={12} sm={6} md={6} >
            <Card>
              <CardHeader
                avatar={
                  <img
                    src={workstationIcon}
                    width={60}
                    style={{ padding: `10px` }}
                    alt="workstation"
                  />
                }
                title={
                  <Typography variant="h5">Meeting room usage breakdown</Typography>  }
                subheader="Keep track of meeting rooms and their occupancy."
                action={
                  <IconButton onClick={handleMenuClick}>
                    <MoreVertIcon />
                  </IconButton>
                }
              />
              <Divider />
              <CardContent>
                <BarChart
                  series={bardata}
                  width={600}
                  height={350}
                  xAxis={[
                    {
                      scaleType: "band",
                      data:month,
                      categoryGapRatio: "0.3",
                      barGapRatio: "0.3",
                },
              ]}
            />
                <Divider />
                <Grid item xs={12} style={{marginTop:'3%'}}>
        <Grid container justifyContent="flex-end">
          <Button variant="outlined">
            View Full Report2
          </Button>
        </Grid>
      </Grid>
          
              </CardContent>
            </Card>
</Grid>
</Grid>
            
    






<Grid item container xs={12} sm={12} md={12} spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
          <Card>
            <CardHeader
              avatar={
                <img
                  src={workstationIcon}
                  width={50}
                  style={{ padding: `10px` }}
                  alt="workstation"
                />
              }
              title={
                <Typography variant="h5">Workstation usage breakdown</Typography>
              }
              subheader="Keep track of workstations and their occupancy."
              action={
                <IconButton onClick={handleMenuClick}>
                  <MoreVertIcon />
                </IconButton>
              }
            />
            <Divider />
            <CardContent>
              <PieChart
                series={piechartData}
                width={600}
                height={300}
              />
              <Divider />
              <Grid item xs={12} style={{marginTop:'3%'}}>
        <Grid container justifyContent="flex-end">
          <Button variant="outlined">
            View Full Report3
          </Button>
        </Grid>
      </Grid>
            </CardContent>
          </Card>
          </Grid>


          <Grid item xs={12} sm={6} md={6} >
          <Card style={{}}>
            <CardHeader
              avatar={
                <img
                  src={workstationIcon}
                  width={50}
                  style={{ padding: `10px` }}
                  alt="workstation"
                />
              }
              title={
                <Typography variant="h5">Meeting room usage breakdown</Typography>
              }
              subheader="Keep track of workstations and their occupancy."
              action={
                <IconButton onClick={handleMenuClick}>
                  <MoreVertIcon />
                </IconButton>
              }
            />
            <Divider />
            <CardContent>
              <PieChart
                series={piechartData}
                width={600}
                height={300}
              />
              <Divider />
              <Grid item xs={12} style={{marginTop:'3%'}}>
        <Grid container justifyContent="flex-end">
          <Button variant="outlined">
            View Full Report4
          </Button>
        </Grid>
      </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Menu */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}>
          <MenuItem onClick={handleMenuClose}>Option 1</MenuItem>
          <MenuItem onClick={handleMenuClose}>Option 2</MenuItem>
          {/* Add more menu items as needed */}
        </Menu>
      </Grid>
      </Grid>
  </Grid>
  
  );
};

export default MeetingAndworkstationaBookingOccupancy;
