const baseURL = `https://chopeai.wegosecure.com/api/`;
//const baseURL = `https://37d3ab160957.ngrok.app/chope/`;

const responseBuilder = async (data) => {
  try {
    if (data.status == 200) {
      const json = await data.json();
      return json;
    } else if (data.status == 400) {
      const json = await data.json();
      var res = {
        statusCode: data.status,
        statusDescription: json.results[0],
      };
      return res;
    } else {
      var res = {
        statusCode: data.status,
        statusDescription: "Something Went Wrong",
      };
      return res;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const postApi = async (data, urlLink) => {
  try {
    console.log(`${baseURL}${urlLink}`);
    const urlBuilder = `${baseURL}${urlLink}`;
    const modelDeploy = await fetch(urlBuilder, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await responseBuilder(modelDeploy);
  } catch (error) {
    console.error(error);
  }
};

const putApi = async (data, urlLink) => {
  try {
    console.log(`${baseURL}${urlLink}`);
    const urlBuilder = `${baseURL}${urlLink}`;
    const modelDeploy = await fetch(urlBuilder, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await responseBuilder(modelDeploy);
  } catch (error) {
    console.error(error);
  }
};

const deleteApi = async (urlLink) => {
  try {
    console.log(`${baseURL}${urlLink}`);
    const urlBuilder = `${baseURL}${urlLink}`;
    const modelDeploy = await fetch(urlBuilder, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return await responseBuilder(modelDeploy);
  } catch (error) {
    console.error(error);
  }
};

const getAPi = async (urlLink) => {
  try {
    console.log(`${baseURL}${urlLink}`);
    const urlBuilder = `${baseURL}${urlLink}`;
    const modelDeploy = await fetch(urlBuilder, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return await responseBuilder(modelDeploy);
  } catch (error) {
    console.error(error);
  }
};

export default {
  getAPi,
  postApi,
  putApi,
  deleteApi,
};
