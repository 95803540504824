import * as React from 'react';
import {Backdrop,CircularProgress} from '@mui/material';



export default function BackDropLoading(open){
    return (
        <div>
          <Backdrop
            sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <CircularProgress color="primary" />
          </Backdrop>
        </div>
      );
}