import React, { useState } from 'react';
import MUIDataTable from "mui-datatables";
import AddIcon from '@mui/icons-material/ControlPoint';

import {
  Grid,
  Typography,
  Toolbar,
  Button,
} from '@mui/material';


const ProjectList= () => {
    const [projectlist, setProjectList] = useState([{ ProjectID: "P23002A", ProjectName: "BASF", Address: "128 Beach road", Country: "Singapore", ProductType: "Access control" },
    { ProjectID: "P23004R", ProjectName: "Nokia", Address: "Haryana", Country: "India", ProductType: "Room booking"  },
    { ProjectID: "P23004L", ProjectName: "Nokia", Address: "Haryana", Country: "India", ProductType: "Locker Booking"  },
    { ProjectID: "P23002W", ProjectName: "BASF", Address: "128 Beach road", Country: "Singapore", ProductType: "Workstation Booking"  }]);

    const columns = [
      {
       name: "ProjectID",
       label: "Project ID",
       options: {
        sort: true,
        setCellHeaderProps: (value) => ({
          align: "center",
        }),
       }
      },
      {
       name: "ProjectName",
       label: "Project Name",
       options: {
        sort: true,
        setCellHeaderProps: (value) => ({
          align: "center",
        }),
       }
      },
      {
       name: "Address",
       label: "Address",
       options: {
        sort: true,
        setCellHeaderProps: (value) => ({
          align: "center",
        }),
       }
      },
      {
       name: "Country",
       label: "Country",
       options: {
        sort: true,
        setCellHeaderProps: (value) => ({
          align: "center",
        }),
       }
      },
      {
        name: "ProductType",
        label: "Product Type",
        options: {
         sort: true,
         setCellHeaderProps: (value) => ({
          align: "center",
        }),
        }
       },
     ];
    
     const options = {
     download : false,
     print : false,
     onRowClick : false,
     filter : false,
     viewColumns : false, 
     selectableRows : "none",
     responsive: "standard"
    };  
  
 return(
  <Grid><Grid><Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
  <Typography variant="h5">Project List</Typography>
  
    </Toolbar>
    </Grid>
  <Grid container 
    spacing={0}
    direction = "column"
>
        <Grid item xs={12}>
          <div>
                   <Button
                      variant="outlined"
                      style={{
                        float: 'right',
                        color: '#FFFFFF',
                        backgroundColor: "#545F71",
                        marginBottom: "20px"
                      }}
                      startIcon={<AddIcon />}
                    >
                      Add New Project
                    </Button>
                    </div>
                </Grid>
        
                <Grid item xs={12}>
                    
              <MUIDataTable
                title={
               <div></div> 
                }
                
                data={projectlist}
                columns={columns}
                options={options}
              />
              
              </Grid>
          </Grid>
          </Grid>
);

};

export default ProjectList;