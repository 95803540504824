import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import Switch from "@mui/material/Switch";
import {
  Grid,
  Autocomplete,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Toolbar,
  Chip,
} from "@mui/material";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import BackDropLoading from "../Components/Loading";
import { showToast } from "../Components/ToastBox";
import DataServices from "../Services/DataServices";

const WorkstationAndMeetingRoom = () => {
  const [data, setData] = useState([
    //   {
    //     Name: "Meeting Room",
    //     Description: "4 Pax",
    //     Location: "Level 2",
    //     Availability:
    //       "Monday, Tuesday, Wednesday, Thursday Friday 09:00 AM - 06:00 PM",
    //     Image:
    //       "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJkAAACUCAMAAAC3HHtWAAAAllBMVEX///8Aht4Ag90Agd0Aedv0+f35/f4PiN/9/PrR3+s7luH///cAfNze5++GuOIAftw0j93q8foAddpKnt4mit3Z6PfI1OKBtOi/1unO4/ayz+dMltzY3+W6z+Kbw+NYp+Zop+V0sOe21fKix+6Xxu4+nOJNk+GOvOB6sN7i5uiSxunA3PSp0O7p9/0AjN1bn+GawO3w8O7g6NcoAAADK0lEQVR4nO2V65LaOBBG1W0bCYfxbSxugzGeQMDhkvX7v9y2bEOGykwyVXhrK1Xf+YGFkOSjVrdQCgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA+G8JguD/VrhnFHRKKeWbT6qNbntoG+0KwejaM9T+bBUShVWqbEbJ0+fmVEn42ja+JslWvoaOH7OjEwvDXTqI2DFnR1wp6//ebPZt04dFhexFXV/sPX8ZEXdosUwz9saDmCWs6eUl8ffmD2apZn39OSSOTGvmsZhp0pfELeRblfqaBzE7Z5oPdT1e1l3MRtb2aXK29tzFSDIotcoS5YurGXMfs96M9qvVnnX8fUAzWWgt2zfGmYVR7vvk0mWx8TJ/spNmGl42pzgrQ9I6nv00u4sZrd1Air+bwcyCTFPeZbP1tWaPNEsGn3MmabO24k7Sx5fcmf348rGZJe0Xw5mpnaTHhL+mrRknUcU6n6vKo8t+LekUKjXRxJtyuRezQ2E+MCv/mcleDs1wp6kWFwkTcWKdWV6YRiI3D17IOzTmJK+0ziyq62YhebbqxH41k2BLhPe1GdBMBYfEHWEY9LWZiZnVFE8lCRPiuTNbui2I2a02fzEjOXkKpXgGNFNmFbmlj2/NLuSWFzNv+ZGZujM7LCUNeDusmbiNZdX1vVncmXHxrhlx5Z6jkrnsKsCYZ3ZBG87s6O6Is6f59MbMXQCy/6N2oXvHbMckVavUK2kvMn1tHmPtL5wZffI/7vfYLA5nVe6y+42ZkkLLT0c5tLC5mYnN1nazXmNxLmc7Ty6UuerNpGx405rtyrJ8tg+anTJyZUVx1dzMaK6eiEmSmjy5hSfUmgU5a2/X/UEElXSyJ2PiqLmaqa1HuU1lvqzI/vZBM3UIZV3KD7WxMb2I2YTkPlPznXTrcN0oJb8W7Sbc5dIflIm6aTLAqFFC2pmlkpanQOct3sk8aGaeivV6OZYXqHkxFZFF+6ka6S5WrjUuirodOl6vp+Zu2rR2824j5PmkxtOO+kEx9xLTtOtLo39cu2+t68jGvDPt58zuaXoeNwMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAP4i/gX2JjmkwtKzqgAAAABJRU5ErkJggg==",
    //     MinBookingDuration: "1",
    //     MinPeriodsInMonts: "1",
    //   },
    //   {
    //     Name: "Meeting Room",
    //     Description: "6 Pax",
    //     Location: "Level 2",
    //     Availability:
    //       "Monday, Tuesday, Wednesday, Thursday Friday 09:00 AM - 06:00 PM",
    //     Image: "",
    //     MinBookingDuration: "30",
    //     MinPeriodsInMonts: "30",
    //   },
    //   {
    //     Name: "Meeting Room",
    //     Description: "8 Pax",
    //     Location: "Level 1",
    //     Availability:
    //       "Monday, Tuesday, Wednesday, Thursday Friday 09:00 AM - 06:00 PM",
    //     Image: "",
    //     MinBookingDuration: "30",
    //     MinPeriodsInMonts: "30",
    //   },
    //   {
    //     Name: "Workstation",
    //     Description: "10 Station",
    //     Location: "Level 8",
    //     Availability:
    //       "Monday, Tuesday, Wednesday, Thursday Friday 09:00 AM - 06:00 PM",
    //     Image: "",
    //     MinBookingDuration: "30",
    //     MinPeriodsInMonts: "30",
    //   },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [orgDropDownOptions, setOrgDropDownOptions] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  useEffect(() => {
    const fetchOrgDropdownData = async () => {
      try {
        setIsLoading(true);
        const response = await DataServices.getOrgDropDownDetailsApi();
        if (response.statusCode == 200) {
          try {
            var dummy = [...response.response.body];
            if (orgDropDownOptions < 1) {
              setOrgDropDownOptions(dummy);
            }
          } catch (error) {
            showToast("Something went wrong ", "error");
          }
        } else {
          showToast(response.statusDescription, "error");
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchOrgDropdownData();
  }, []);

  useEffect(() => {
    const meetingRoomSetupCall = async (data) => {
      setIsLoading(true);

      const response = await DataServices.getWorkstationAndMeetingRoomSetupApi(
        data
      );
      if (response && response?.statusCode == 200) {
        setIsLoading(false);
        setData(response.response.body);
      } else {
        setIsLoading(false);
        console.log(response);
      }
    };

    meetingRoomSetupCall(selectedOrganization ?? "");
  }, [selectedOrganization]);

  const handleImageClick = (rowIndex, colIndex) => {
    setSelectedRowIndex(rowIndex);
  };
  const insertPhotoIcon = <InsertPhotoIcon color="primary" />;

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const newData = [...data];
      const reader = new FileReader();

      reader.onload = (e) => {
        newData[selectedRowIndex][5] = e.target.result; // Assuming the 'image' column is at index 2
        setData(newData);
      };

      reader.readAsDataURL(file);
      setSelectedRowIndex(null);
    }
  };

  const columns = [
    {
      name: "roomName",
      label: "Name",
      options: {
        sort: true,
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    // {
    //   name: "description",
    //   label: "Description",
    //   options: {
    //     sort: true,
    //     customBodyRender: (value) => (
    //       <div style={{ textAlign: "center" }}>{value}</div>
    //     ),
    //   },
    // },
    // {
    //   name: "location",
    //   label: "Location",
    //   options: {
    //     sort: true,
    //   },
    // },
    {
      name: "capacity",
      label: "Capacity",
      options: {
        sort: true,
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      name: "remarks",
      label: "Availability",
      options: {
        sort: true,
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },

    {
      name: "Image",
      label: "Image",
      options: {
        sort: true,
        filtetType: "multiselect",
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;
          const colIndex = tableMeta.colIndex;

          if (value) {
            return (
              <img
                src={value}
                alt="Preview"
                style={{ width: "50px", height: "50px", cursor: "pointer" }}
                onClick={() => handleImageClick(rowIndex, colIndex)}
              />
            );
          } else {
            return (
              <button onClick={() => handleImageClick(rowIndex, colIndex)}>
                {insertPhotoIcon}
              </button>
            );
          }
        },
      },
    },
    // {
    //   name: "MinBookingDuration",
    //   label: "Min Booking Duration",
    //   options: {
    //     sort: true,
    //     filtetType: "multiselect",
    //   },
    // },
    // {
    //   name: "MinPeriodsInMonts",
    //   label: "Min Periods In Months",
    //   options: {
    //     sort: true,
    //     filtetType: "multiselect",
    //   },
    // },

    {
      name: "status",
      label: "Status",
      options: {
        sort: false,
        customBodyRender: (value) => {
          let chipProps = {};
          switch (value.toLowerCase()) {
            case "active":
              chipProps = { label: value, color: "success" };
              break;
            case "disabled":
              chipProps = { label: value, variant: "filled", disabled: "true" };
              break;
            case "in progress":
              chipProps = { label: value, variant: "outlined" };
              break;
            default:
              chipProps = { label: value };
          }
          return <Chip {...chipProps} />;
        },
      },
    },
  ];
  const options = {
    download: false,
    print: false,
    onRowClick: false,
    filter: false,
    viewColumns: false,
    selectableRows: "none",
    responsive: "standard",
  };

  const entities = [
    { label: "AITS" },
    { label: "Organization B" },
    { label: "Organization C" },
    { label: "Organization D" },
  ];

  return (
    <Grid>
      <Grid>
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          {/* <Typography variant="h5">Workstation & Meeting Room</Typography> */}
          <Typography variant="h5"> Meeting Room Setup</Typography>
        </Toolbar>
      </Grid>
      <Grid container spacing={0} direction="column">
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            id="OrganizationName"
            options={orgDropDownOptions}
            sx={{ width: 250, float: "left", marginBottom: "20px" }}
            onChange={(event, newValue) =>
              setSelectedOrganization(newValue?.value)
            }
            renderInput={(params) => (
              <TextField {...params} label="Organization Name" />
            )}
          />
          {/* <FormControlLabel
            value="start"
            control={<Switch color="warning" />}
            label="Show Booking User Name to Other User"
            labelPlacement="start"
          /> */}
        </Grid>

        <Grid container direction="row">
          <Grid item md={8} xs={12}>
            <Typography>
              Set up Workstation & Meeting Room. Users can book these Rooms
              through CHOPE AI.{" "}
            </Typography>
          </Grid>
          {/* <Grid item md={4} xs={12}>
      <Button
                      variant="outlined"
                      style={{
                        marginLeft:"70px",
                        color: '#FFFFFF',
                        backgroundColor: "#00C0F0",
                        marginBottom: "20px"
                      }} 
                    >
                     View Bookings
                    </Button>
  

      <Button
                      variant="outlined"
                      style={{
                        marginLeft:"10px",
                        color: '#FFFFFF',
                        backgroundColor: "#65B741",
                        marginBottom: "20px"
                      }}
                    >
                     Next
                    </Button>
    </Grid> */}
        </Grid>

        <Grid item xs={12}>
          <MUIDataTable
            title={<div> </div>}
            data={data}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
      {isLoading && <BackDropLoading open={isLoading} />}
    </Grid>
  );
};

export default WorkstationAndMeetingRoom;
