import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const sessionDetails = () => {
  return sessionStorage.getItem(`loggedInUserDetails`);
};

const PrivateRoute = ({ children }) => {
  const sessionData = sessionDetails();
  const location = useLocation();

  if (!sessionData) {
    return <Navigate to="/login" />;
  }
  return children;
};

export default PrivateRoute;
