import React, { useState } from 'react';
import MUIDataTable from "mui-datatables";
import { BarChart, PieChart } from "@mui/x-charts";
import { TextField, Chip, Autocomplete, Grid, Box } from "@mui/material";

const LockerOccupancy = () => {
  const [locker, setLocker] = useState([
    { LockerID: "L001", unitNO: "08-13", projectId: "P23001", status: "Available" },
    { LockerID: "L002", unitNO: "08-13", projectId: "P23002", status: "Occupied" },
    { LockerID: "L003", unitNO: "08-13", projectId: "P23003", status: "Available" },
    { LockerID: "L004", unitNO: "08-13", projectId: "P23004", status: "Occupied" }
  ]);

  const columns = [
    { name: "LockerID", label: "Locker ID", options: { sort: true } },
    { name: "unitNO", label: "Unit NO", options: { sort: true } },
    { name: "projectId", label: "Project Id", options: { sort: true } },
    {
      name: "status",
      label: "Status",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          let color, backgroundColor;

          switch (value.toLowerCase()) {
            case "available":
              color = "#fff";
              backgroundColor = "#56BD66";
              break;
            case "occupied":
              color = "#fff";
              backgroundColor = "#EA3D32";
              break;
          }

          return (
            <div style={{ textAlign: 'center' }}>
              <Chip
                label={value}
                style={{
                  backgroundColor,
                  color,
                }}
              />
            </div>
          );
        },
      },
    },
  ];

  const bardata = [
    {
      data: [200, 400, 600, 500],
    },
  ];

  const Occupancy = ["Available", "Occupied", "Case3", "Case4"];

  const options = {
    download: false,
    print: false,
    onRowClick: false,
    viewColumns: false,
    selectableRows: "none",
    responsive: "standard",
    filterType: true,
  };

  const organization = [
    { label: 'AITS' },
    { label: 'Organization B' },
    { label: 'Organization C' },
    { label: 'Organization D' },
  ];

  return (

    <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="100vh">
    <Grid >
      <Grid container spacing={0} direction="row">
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            id="OrganizationName"
            options={organization}
            sx={{ width: 250, float: 'left', marginBottom: '20px' }}
            renderInput={(params) => <TextField {...params} label="Organization Name" />}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <BarChart
            series={bardata}
            width={450}
            height={350}
            xAxis={[
              {
                scaleType: "band",
                data: Occupancy,
                categoryGapRatio: "0.3",
                barGapRatio: "0.3",
              },
            ]}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <BarChart
            series={bardata}
            width={450}
            height={350}
            xAxis={[
              {
                scaleType: "band",
                data: Occupancy,
                categoryGapRatio: "0.3",
                barGapRatio: "0.3",
              },
            ]}
          />
        </Grid>

        <Grid item xs={12}>
          <MUIDataTable
            title={<div> </div>}
            data={locker}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
    </Grid>
    </Box>
  );
};

export default LockerOccupancy;