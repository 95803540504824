import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import {
  Card,
  Paper,
  Typography,
  Button,
  Chip,
  Grid,
  Autocomplete,
  TextField,
} from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/ControlPoint";
import AppText from "../../Utils/AppText";
import DataServices from "../../Services/DataServices";
import { showToast } from "../../Components/ToastBox";
import BackDropLoading from "../../Components/Loading";
import DeleteConfirmationDialog from "../../Components/DeleteConfirmationDialog";

const ProjectOverView = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const handleCreateProject = () => {
    navigate("/projects/createProjects");
  };
  const [projects, setProjects] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [projectIdToDelete, setProjectIdToDelete] = useState("");
  const [orgDropDownOptions, setOrgDropDownOptions] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  const handleModal = (data) => {
    console.log("data", data);
    setModalOpen(true);
    setProjectIdToDelete(data);
  };

  const fetchOrgDropdownData = async () => {
    try {
      setIsLoading(true);
      const response = await DataServices.getOrgDropDownDetailsApi();
      if (response.statusCode == 200) {
        try {
          var dummy = [...response.response.body];
          if (orgDropDownOptions < 1) {
            setOrgDropDownOptions(dummy);
          }
        } catch (error) {
          showToast("Something went wrong ", "error");
        }
      } else {
        showToast(response.statusDescription, "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getAllProjectsDetailsCall = async (data) => {
    try {
      setIsLoading(true);
      const response = await DataServices.getProjectsDetilsApi(data);
      if (response?.statusCode == 200) {
        try {
          setProjects(response.response.body);
        } catch (error) {
          showToast("Something went wrong ", "error");
        }
      } else {
        showToast(response?.statusDescription, "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrgDropdownData();
  }, []);

  useEffect(() => {
    getAllProjectsDetailsCall(selectedOrganization ?? "");
  }, [selectedOrganization]);

  const columns = [
    {
      name: "projectId",
      label: "Project ID",
      options: {
        sort: true,
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      name: "projectName",
      label: "Project Name",
      options: {
        sort: false,
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      name: "organizationName",
      label: "Organization Name",
      options: {
        sort: false,
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      name: "country",
      label: "Country",
      options: {
        sort: true,
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      name: "product",
      label: "Product Type",
      options: {
        sort: true,
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        sort: false,
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    // {
    //   name: "status",
    //   label: "Status",
    //   options: {
    //     sort: false,
    //     customBodyRender: (value) => {
    //       let chipProps = {};
    //       switch (value) {
    //         case "Active":
    //           chipProps = { label: value, color: "success" };
    //           break;
    //         case "Disabled":
    //           chipProps = { label: value, variant: "filled", disabled: "true" };
    //           break;
    //         case "In progress":
    //           chipProps = { label: value, variant: "outlined" };
    //           break;
    //         default:
    //           chipProps = { label: value };
    //       }
    //       return <Chip {...chipProps} />;
    //     },
    //   },
    // },

    {
      name: "Actions",
      options: {
        sort: false,
        customBodyRenderLite: (dataIndex) => (
          <div>
            <span
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => handleEdit(projects[dataIndex])}
            >
              <EditIcon color="primary" />
            </span>
            <span
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => handleModal(projects[dataIndex]?.projectId)}
            >
              <DeleteIcon />
            </span>
          </div>
        ),
      },
    },
  ];

  const options = {
    filter: true,
    search: true,
    pagination: true,
    selectableRows: "none", // or "multiple" for checkboxes
    serverSide: false,
    download: false,
    print: false,
    viewColumns: false,
    responsive: "standard",
  };

  const handleEdit = (rowData) => {
    // Handle edit action, rowData contains the data for the clicked row
    navigate("/projects/editProjects", { state: rowData });
  };

  const handleDeleteConfirm = async () => {
    setModalOpen(false);
    if (projectIdToDelete.length > 0) {
      try {
        setIsLoading(true);
        console.log("projectIdToDelete", projectIdToDelete);
        const response = await DataServices.deleteProjectsApi(
          projectIdToDelete
        );
        if (response?.statusCode == 200) {
          try {
            await getAllProjectsDetailsCall();
            showToast(response.response.body.message, "success");
          } catch (error) {
            showToast("Something went wrong ", "error");
          }
        } else {
          showToast(response?.statusDescription, "error");
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      showToast("Unable to process", "error");
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item>
        <Typography variant="h5">{AppText.heading.ProjectOverview}</Typography>
      </Grid>
      <Grid
        item
        container
        justifyContent="space-around"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12} sm={6} md={4}>
          <Card style={{ width: "100%", height: "400px", padding: "20px" }}>
            <Paper
              elevation={2}
              style={{
                height: "70%",
                backgroundColor: colorConfigs.colorCode.tertiery,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h4">20%</Typography>
            </Paper>
            <Typography
              variant="h6"
              align="center"
              style={{ marginTop: "10px" }}
            >
              Total Project: 1000
            </Typography>
            <Typography variant="body1" align="center">
              Active project: 900
            </Typography>
            <Typography variant="body1" align="center">
              In progress: 20
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card style={{ width: "100%", height: "400px", padding: "20px" }}>
            <Paper
              elevation={2}
              style={{
                height: "70%",
                backgroundColor: colorConfigs.colorCode.tertiery,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h4">+20%</Typography>
            </Paper>
            <Typography
              variant="h6"
              align="center"
              style={{ marginTop: "10px" }}
            >
              New project this month
            </Typography>
            <Typography variant="body1" align="center">
              (compared with previous month)
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card style={{ width: "100%", height: "400px", padding: "20px" }}>
            <Paper
              elevation={2}
              style={{
                height: "70%",
                backgroundColor: colorConfigs.colorCode.tertiery,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h4">+20%</Typography>
            </Paper>
            <Typography
              variant="h6"
              align="center"
              style={{ marginTop: "10px" }}
            >
              Project In progress
            </Typography>
            <Typography variant="body1" align="center">
              (requires configuration)
            </Typography>
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: "2%", marginBottom: "2%" }}>
        <Grid container justifyContent="flex-end">
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              id="OrganizationName"
              options={orgDropDownOptions}
              sx={{ width: 250, float: "left", marginBottom: "20px" }}
              onChange={(event, newValue) =>
                setSelectedOrganization(newValue?.value)
              }
              renderInput={(params) => (
                <TextField {...params} label="Organization" />
              )}
            />
          </Grid>
          <Button
            variant="contained"
            onClick={handleCreateProject}
            startIcon={<AddIcon />}
          >
            {AppText.buttonText.addNewProject}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} sm={12}>
        <MUIDataTable data={projects} columns={columns} options={options} />
      </Grid>

      <DeleteConfirmationDialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleDeleteConfirm}
      />

      {isLoading && <BackDropLoading open={isLoading} />}
    </Grid>
  );
};

export default ProjectOverView;
