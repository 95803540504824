import React from "react";
import { Route, Routes } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material";
import "./App.css";
import MainLayout from "./LayoutController/MainLayout";
import { routes } from "./Routes";
import { lightTheme } from "./Utils/theme";
import Login from "./Authentication/Login";
import Toastbox from "./Components/ToastBox";
import PrivateRoute from "./PrivateRoutes";

const App = () => {
  return (
    <React.Fragment>
      <CssBaseline enableColorScheme={true} />
      <Toastbox />
      <ThemeProvider theme={lightTheme}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <MainLayout />
              </PrivateRoute>
            }
          >
            {routes}
          </Route>
        </Routes>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default App;
