import React, { useState } from 'react';
import MUIDataTable from "mui-datatables";
import { Card, Paper, Typography, Chip, Grid } from '@mui/material';
import colorConfigs from '../../configs/colorConfigs';


const SubscriptonOverView = () => {

  const [data, setData] = useState([
    {
      "Products": "Locker, Access control, Room booking, Workstation booking",
      "Start Date": "10/11/2022",
      "End Date": "10/11/2023",
      "Contract ID": "8898266729a",
      "Status": "Expiring"
    },
    {
      "Products": "Current Contracts",
      "Start Date": "10/11/2022",
      "End Date": "10/11/2025",
      "Contract ID": "8898266723a",
      "Status": "Active"
    },
    {
      "Products": "BMS",
      "Start Date": "10/11/2022",
      "End Date": "10/11/2025",
      "Contract ID": "8898266729a",
      "Status": "Active"
    },
    {
      "Products": "Access control",
      "Start Date": "10/11/2022",
      "End Date": "10/11/2025",
      "Contract ID": "8898266729a",
      "Status": "Active"
    },
    {
      "Products": "Access control",
      "Start Date": "10/11/2022",
      "End Date": "10/11/2025",
      "Contract ID": "8898266729a",
      "Status": "Active"
    },
    {
      "Products": "Access control",
      "Start Date": "10/11/2022",
      "End Date": "10/11/2025",
      "Contract ID": "8898266729a",
      "Status": "Active"
    },
    {
      "Products": "Access control",
      "Start Date": "10/11/2022",
      "End Date": "10/11/2025",
      "Contract ID": "8898266729a",
      "Status": "Active"
    },
    {
      "Products": "Access control",
      "Start Date": "10/11/2022",
      "End Date": "10/11/2025",
      "Contract ID": "8898266729a",
      "Status": "Active"
    }
  ]
  );

  const columns = [
    {
      name: "Products",
      label: "Products",
      options: {
        sort: true,

      },
    },
    {
      name: "Start Date",
      label: "Start Date",
      options: {
        sort: false,
      },
    },
    {
      name: "End Date",
      label: "End Date",
      options: {
        sort: false,
      },
    },
    {
      name: "Contract ID",
      label: "Contract ID",
      options: {
        sort: true,
      },
    },


    {
      name: "Status",
      label: "Status",
      options: {
        sort: false,
        customBodyRender: (value) => {
          let chipProps = {};
          switch (value) {
            case 'Active':
              chipProps = { label: value, color: "success" };
              break;
            case 'Expiring':
              chipProps = { label: value, variant: "filled", disabled: 'true' };
              break;

            default:
              chipProps = { label: value };
          }
          return <Chip {...chipProps} />;
        }
      },
    },
  ];



  const options = {
    filter: true,
    search: true,
    pagination: true,
    selectableRows: "none", // or "multiple" for checkboxes
    serverSide: false,
    download: false,
    print: false,
    viewColumns: false,
    responsive: "standard"
  };

  return (
    <Grid container spacing={3} >

      <Grid item container justifyContent="space-around" alignItems="center" spacing={3}>
        <Grid item xs={12} sm={6} md={4} >
          <Card style={{ width: '100%', height: '400px', padding: '20px' }}>
            <Paper elevation={2} style={{ height: '70%', backgroundColor: colorConfigs.colorCode.tertiery, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant="h5">20 of 100</Typography>
            </Paper>
            <Typography variant="h6" align="center" style={{ marginTop: '10px', textAlign: 'center' }}>Enterprise licenses</Typography>

            <Typography variant="body1" align="center">Assigned :20</Typography>
            <Typography variant="body1" align="center">Available :100</Typography>
          </Card>

        </Grid>
        <Grid item xs={12} sm={6} md={4} >
          <Card style={{ width: '100%', height: '400px', padding: '20px' }}>
            <Paper elevation={2} style={{ height: '70%', backgroundColor: colorConfigs.colorCode.tertiery, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant="h5">80 of 100</Typography>
            </Paper>
            <Typography variant="h6" align="center" style={{ marginTop: '10px', textAlign: 'center' }}>Individual licenses</Typography>
            <Typography variant="body1" align="center">Assigned Lincenses:80</Typography>
            <Typography variant="body1" align="center">Available :100</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} >
          <Card style={{ width: '100%', height: '400px', padding: '20px' }}>
            <Paper elevation={2} style={{ height: '70%', backgroundColor: colorConfigs.colorCode.tertiery, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant="h5">+20%</Typography>
            </Paper>
            <Typography variant="h6" align="center" style={{ marginTop: '10px', textAlign: 'center' }}>Project In progress</Typography>
            <Typography variant="body1" align="center">(requires configuration)</Typography>
          </Card>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} sm={12}>
        <MUIDataTable
          data={data}
          columns={columns}
          options={options}
        />
      </Grid>
    </Grid>
  );
};

export default SubscriptonOverView;
