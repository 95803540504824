import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  CardContent,
  Card,
  Link,
  TextField,
  Button,
  Grid,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../Assets/logo.png";
import BackDropLoading from "../Components/Loading";
import colorConfigs from "../configs/colorConfigs";
import { LoginValidateForm } from "./FormValidation";
import { showToast } from "../Components/ToastBox";
import DataServices from "../Services/DataServices";
import AppText from "../Utils/AppText";
import { sha256 } from "../Utils/CommonFunctions";

let validation = {
  email: false,
  password: false,
};

let validationMsg = {
  email: "",
  password: "",
};

const Login = () => {
  const navigate = useNavigate();
  const [valid, setValid] = useState(validation);
  const [msg, setMsg] = useState(validationMsg);
  const [emailId, setEmailId] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setValid(validation);
    setMsg(validationMsg);
  }, [emailId, password]);

  const login = async (data) => {
    try {
      setIsLoading(true);
      const response = await DataServices.loginApi(data);
      if (response.statusCode == 200) {
        try {
          let loggedInUser = response.response.body;
          let loggedInUserDetails = JSON.stringify(loggedInUser);
          sessionStorage.setItem("loggedInUserDetails", loggedInUserDetails);
          sessionStorage.setItem("accessToken", loggedInUser.accessToken);
          sessionStorage.setItem("userRole", loggedInUser.roleName);
          navigate(`/dashboard`);
        } catch (error) {
          showToast("Something went wrong ", "error");
        }
      } else {
        showToast(response.statusDescription, "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!emailId || !password) {
      showToast(AppText.emptyLoginfields, "warning");
      return;
    }
    const hashedPassword = await sha256(password);
    let data = {
      emailId: emailId,
      password: hashedPassword,
      role: "SUPERADMIN",
    };
    await login(data);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{ backgroundColor: colorConfigs.sidebar.bg, minHeight: "100vh" }}
    >
      <Grid item>
        <Grid item xs={12} sm={12} md={12}></Grid>
        <Card sx={{ maxWidth: 500 }}>
          <CardContent>
            <Stack direction="row" alignItems="center">
              <img
                src={logo}
                width={60}
                style={{ paddingRight: `5px` }}
                alt="chope"
              />
              <Typography variant="h5" style={{ color: "black" }}>
                {AppText.chopeAi}
              </Typography>
            </Stack>
            <Box
              sx={{
                marginTop: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  error={valid.email}
                  onChange={(e) => setEmailId(e.target.value)}
                  helperText={valid.email ? msg.email : ""}
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  error={valid.password}
                  helperText={valid.password ? msg.password : ""}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="current-password"
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, borderRadius: 3 }}
                  onClick={() => handleSubmit()}
                >
                  Sign In
                </Button>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ padding: `10px` }}
                >
                  <Grid item xs={4} sm={6} md={6}>
                    <Link
                      onClick={() => navigate("/recoverpassword")}
                      variant="body2"
                    >
                      Recover Password
                    </Link>
                  </Grid>
                  <Grid item xs={8} sm={6} md={6}>
                    <Link
                      onClick={() => navigate("/signup")}
                      variant="body2"
                      style={{ float: "right" }}
                    >
                      {"Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </CardContent>
          {isLoading && <BackDropLoading open={isLoading} />}
          <ToastContainer />
        </Card>
      </Grid>
    </Grid>
  );
};

export default Login;
