import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import SubscriptionsOutlinedIcon from "@mui/icons-material/SubscriptionsOutlined";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import ViewTimelineOutlinedIcon from "@mui/icons-material/ViewTimelineOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LinkIcon from "@mui/icons-material/Link";
import ManageHistoryOutlinedIcon from "@mui/icons-material/ManageHistoryOutlined";
import SettingsOverscanIcon from "@mui/icons-material/SettingsOverscan";
import CreateProject from "../Pages/SuperAdminProjects/CreateProject";
import ProjectOverView from "../Pages/SuperAdminProjects/ProjectOverView";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import LockClockIcon from "@mui/icons-material/LockClock";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import WorkspacesOutlinedIcon from "@mui/icons-material/WorkspacesOutlined";
import ThreePIcon from "@mui/icons-material/ThreeP";
import ScreenshotMonitorOutlinedIcon from "@mui/icons-material/ScreenshotMonitorOutlined";
import UwbTrackingPage from "../Pages/UWBtracking/UwbTracking";
import WorkIcon from "@mui/icons-material/Work";
import SubscriptionTabs from "../Pages/Subscription/SubscriptionTabs";
import SuperAdminUsers from "../Pages/Subscription/SuperAdminUsers/SuperAdminUsers";
import Activityhistory from "../Pages/User/Activityhistory";

import Userlayout from "./Userlayout";
import User from "../Pages/User/User";

import ProjectList from "../Pages/ProjectList";
import UserList from "../Pages/UserList";
import OrganizationOverview from "../Pages/OrganizationOverview";
import WorkStationBookingHistory from "../Pages/WorkStationBookingHistory";
import MeetingRoomBookingHistory from "../Pages/MeetingRoomBookingHistory";
import LockerBookingHistory from "../Pages/LockerBookingHistory";
import WorkstationAndMeetingRoom from "../Pages/WorkstationAndMeetingRoom";
import Home from "../Pages/Home";
import ProjectLayout from "./ProjectLayout";
import HistoryLayout from "./HistoryLayout";
import LockerOccupancy from "../Pages/LockerOccupancy";
import MeetingAndworkstationaBookingOccupancy from "../Pages/MeetingAndWorkstationaBookingOccupancy.js";
import EditUser from "../Pages/User/EditUser.js";
import EditProject from "../Pages/SuperAdminProjects/EditProject.js";

const appRoutes = [
  {
    path: "/dashboard",
    element: <Home />,
    state: "Dashboard",
    sidebarProps: {
      displayText: "Home",
      icon: <HomeOutlinedIcon />,
    },
  },
  {
    path: "/uwbTracking",
    element: <UwbTrackingPage />,
    state: "RTLS",
    sidebarProps: {
      displayText: "RTLS",
      icon: <ViewTimelineOutlinedIcon />,
    },
  },

  {
    path: "/history",
    element: <HistoryLayout />,
    state: "History",
    sidebarProps: {
      displayText: "History",
      icon: <ManageHistoryOutlinedIcon />,
    },
    child: [
      {
        path: "/history/MeetingRoom",
        element: <MeetingRoomBookingHistory />,
        state: "MeetingRoomBookingHistory",
        sidebarProps: {
          displayText: "Meeting Room Booking History",
          icon: <MeetingRoomIcon />,
        },
      },
      {
        path: "/history/locker",
        element: <LockerBookingHistory />,
        state: "LockerBookingHistory",
        sidebarProps: {
          displayText: "Locker Booking History",
          icon: <LockClockIcon />,
        },
      },

      {
        path: "/history/workstation/",
        element: <WorkStationBookingHistory />,
        state: "WorkStationBookingHistory",
        sidebarProps: {
          displayText: "Workstation Booking History",
          icon: <WorkspacesOutlinedIcon />,
        },
      },
      {
        path: "/history/MeetingRoomAndWorkstation/Occupancy",
        element: <MeetingAndworkstationaBookingOccupancy />,
        state: "MeetingRoomAndWorkstationOccupancy",
        sidebarProps: {
          displayText: "Meeting Room And Workstation Occupancy",
          icon: <ScreenshotMonitorOutlinedIcon />,
        },
      },
      {
        path: "/history/locker/Occupancy",
        element: <LockerOccupancy />,
        state: "LockerOccupancy",
        sidebarProps: {
          displayText: "Locker Occupancy",
          icon: <ScreenshotMonitorOutlinedIcon />,
        },
      },
    ],
  },

  {
    path: "/projects",
    element: <ProjectOverView />,
    state: "Projects",
    sidebarProps: {
      displayText: "Projects",
      icon: <LayersOutlinedIcon />,
    },
    // child: [
    //   // {
    //   //   path: "/projects/overview",
    //   //   element: <ProjectOverView />,
    //   //   state: "ProjectsOverview",
    //   //   // sidebarProps: {
    //   //   //   displayText: "Projects Overview",
    //   //   //   icon: <SettingsOverscanIcon />,
    //   //   // },
    //   // },
    //   {
    //     path: "/projects/projectlist",
    //     element: <ProjectList />,
    //     state: "ProjectList",

    //   },
    // ],
  },

  {
    path: "/projects/projectlist",
    element: <ProjectList />,
    state: "ProjectList",
  },

  {
    path: "/projects/createProjects",
    element: <CreateProject />,
    state: "CreateProject",
  },

  {
    path: "/projects/editProjects",
    element: <EditProject />,
    state: "EditProject",
  },

  {
    path: "/subscription",
    element: <SubscriptionTabs />,
    state: "Subscription",
    sidebarProps: {
      displayText: "Subscription",
      icon: <SubscriptionsOutlinedIcon />,
    },
  },

  {
    path: "/users",
    element: <Userlayout />,
    state: "Users",
    sidebarProps: {
      displayText: "Users",
      icon: <PeopleAltOutlinedIcon />,
    },
    child: [
      {
        path: "/users/SuperAdminUsers",
        element: <SuperAdminUsers />,
        state: "superAdminUsers",
        // sidebarProps: {
        //   displayText: "Super Admin Users",
        //   icon: <ManageAccountsIcon />,
        // },
      },
      {
        path: "/users/CreateUser",
        element: <User />,
        state: "CreateUser",
        sidebarProps: {
          displayText: "Create New User",
          icon: <LinkIcon />,
        },
      },

      {
        path: "/users/EditUser",
        element: <EditUser />,
        state: "EditUser",
        // sidebarProps: {
        //   displayText: "Create New User",
        //   icon: <LinkIcon/>

        // }
      },
      {
        path: "/users/Userlist",
        element: <UserList />,
        state: "UserList",
        sidebarProps: {
          displayText: "User List",
          icon: <ThreePIcon />,
        },
      },

      {
        path: "/users/Activityhistory",
        element: <Activityhistory />,
        state: "Activityhistory",
        sidebarProps: {
          displayText: "Activity History",
          icon: <LinkIcon />,
        },
      },
    ],
  },

  {
    path: "/Organization/Organizationoverview",
    element: <OrganizationOverview />,
    state: "OrganizationOverview",
    sidebarProps: {
      displayText: "Organization Overview",
      icon: <CorporateFareIcon />,
    },
  },

  {
    path: "/RoomsSetup",
    element: <WorkstationAndMeetingRoom />,
    state: "WorkstationAndMeetingRoom",
    sidebarProps: {
      displayText: "Meeting Rooms Setup",
      icon: <BookOnlineIcon />,
    },
  },
];

export default appRoutes;
