import React,{useState} from 'react';

import { Card, Typography ,Grid,TextField} from '@mui/material';



const BuyMoreLicenses = () => {

  const [organizationId, setOrganizationId] = useState('');

  return (
    <Grid container spacing={3} >
    
    <Grid item container justifyContent="space-around" alignItems="center" spacing={3}>
    <Grid item xs={12} sm={6} md={4}>
  <Card style={{ width: '100%', height: '400px', padding: '20px' }}>
    <Typography variant='h5' style={{ textAlign: 'initial' }}>Monthly</Typography>
    <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
      <Typography variant='h6' style={{ textAlign: 'center' }}>$10</Typography>
      
    </Grid>
    <Typography style={{ textAlign: 'initial' }}>cancel anytime</Typography>
  </Card>
</Grid>
      <Grid item xs={12} sm={6} md={4} >
        <Card style={{ width: '100%', height: '400px', padding: '20px' }}>
        <Typography variant='h5' style={{textAlign:'initial'}}>Monthly</Typography>
        <Typography variant='body2' style={{textAlign:'initial'}}>Billed monthly</Typography>
        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
      <Typography variant='h6' style={{ textAlign: 'center' }}>$100</Typography>
      
    </Grid>
       <Typography  style={{textAlign:'initial'}}>cancel anytime</Typography>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4} >
        <Card style={{ width: '100%', height: '400px', padding: '20px' }}>
        <Typography variant='h5' style={{textAlign:'initial'}}>Yearly</Typography>
        <Typography variant='body2' style={{textAlign:'initial'}}>Billed monthly</Typography>
        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
      <Typography variant='h6' style={{ textAlign: 'center' }}>$100</Typography>
      
    </Grid>
       <Typography  style={{textAlign:'initial'}}>cancel anytime</Typography>
        </Card>
      </Grid>
    </Grid>
 
    <Grid item  xs={12} md={12} sm={12}>
    <TextField
              label="Organization ID"
              value={organizationId}
              onChange={(e) => setOrganizationId(e.target.value)}
              fullWidth
              margin="normal"
          
            />
    </Grid>
  </Grid>
  );
};

export default BuyMoreLicenses;
