import React, { useState } from 'react';
import { Grid, Typography, Card, CardContent, TextField, Button, Toolbar, MenuItem, Divider, InputAdornment, FormControlLabel, Switch, Checkbox } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import AppText from '../../../Utils/AppText';


const SuperAdminUsers = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [organization, setOrganization] = useState('');
  const [role, setRole] = useState('');
  const [userName, setUserName] = useState('');

  const [website, setWebsite] = useState('');
  const [description, setDescription] = useState('');
  const [country, setCountry] = useState('');
  const [timezone, setTimezone] = useState('');

  const [receiveEmailAlerts, setReceiveEmailAlerts] = useState(false);
  const [accessReports, setAccessReports] = useState(false);
  const [availableForProjects, setAvailableForProjects] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const countryOptions = [
    { id: 1, name: 'Country 1' },
    { id: 2, name: 'Country 2' },
    { id: 3, name: 'Country 3' },
    // Add more countries as needed...
  ];

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    // Additional logic to manage user actions based on checkbox state
  };

  const handleReports = () => {
    setAccessReports((prev) => !prev);

  };

  const handleEmailAlertsChange = () => {
    setReceiveEmailAlerts((prev) => !prev);

  };

  const handleAvailableForProjectsChange = () => {
    setAvailableForProjects((prev) => !prev);

  };




  const handleSaveChanges = () => {
    // Logic to save changes
  };

  const handleCancel = () => {
    // Logic to cancel and go back
  };



  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <Grid container >
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="h5">{AppText.heading.users}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} >
              <Grid container justifyContent="flex-end" spacing={1}>

                <Grid item>
                  <Button
                    variant="outlined"
                    startIcon={<PersonAddAltIcon />}
                  >
                    {AppText.buttonText.addUser}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"

                  >
                    {AppText.buttonText.viewAlUsers}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>


        </Toolbar>
      </Grid>
      {/* Personal Info */}
      <Grid item xs={12} md={3} sm={4} >
        <Typography variant="body1">{AppText.superAdminUsers.subTitle1}</Typography>
        <Typography variant="body2">{AppText.superAdminUsers.subText1}</Typography>
      </Grid>

      <Grid item xs={12} md={9} sm={8}>
        <Card>
          <CardContent>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6} sm={6}>
                <TextField
                  label={AppText.fields.firstName}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  fullWidth
                  margin="normal"
                  size='small'
                >

                </TextField>
              </Grid>
              <Grid item xs={6} md={6} sm={6}>
                <TextField
                  label={AppText.fields.lastName}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  fullWidth
                  margin="normal"
                  size='small'
                />
              </Grid>
            </Grid>
            <TextField
              label={AppText.fields.emailAddress}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              margin="normal"
              size='small'
            />
            <TextField
              label={AppText.fields.mobileNo}
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              fullWidth
              margin="normal"
              size='small'
            />
            <TextField
              label={AppText.fields.organization}
              value={organization}
              onChange={(e) => setOrganization(e.target.value)}
              fullWidth
              margin="normal"
              size='small'
            />
            <Divider />
            <Grid style={{ marginTop: '20px', textAlign: 'right' }}>
              <Button variant="outlined" onClick={handleCancel}>{AppText.Cancel}</Button>
              <Button variant="contained" onClick={handleSaveChanges} style={{ marginLeft: '10px' }}>{AppText.SaveChanges}</Button>
            </Grid>
          </CardContent>
        </Card>
      </Grid>


      {/* Role*/}
      <Grid item xs={12} md={3} sm={4} >
        <Typography variant="body1">{AppText.role}</Typography>
      </Grid>
      <Grid item xs={12} md={9} sm={8}>

        <TextField
          select
          style={{ width: '50%' }}
          label="Role"
          value={role}
          onChange={(e) => setRole(e.target.value)}
          margin="normal"
          size='small'
        />
      </Grid>

      <Grid item xs={12} md={3} sm={4} >
        <Typography variant="body1">{AppText.Privileges}</Typography>
        <Typography variant="body2">{AppText.superAdminUsers.subText2}</Typography>
      </Grid>
      <Grid item xs={12} md={9} sm={8} >
        <Grid>
          <FormControlLabel
            control={
              <Switch
                checked={accessReports}
                onChange={handleReports}
                color="primary"
              />
            }
            label={<div>
              <Typography variant="body1">Reports</Typography>
              <Typography variant="body2">
                View, download and edit reports
              </Typography>
            </div>
            }
          />
        </Grid>
        <Grid>
          <FormControlLabel
            control={
              <Switch
                checked={receiveEmailAlerts}
                onChange={handleEmailAlertsChange}
                color="primary"
              />
            }
            label={<div>
              <Typography variant="body1">Available for projects</Typography>
              <Typography variant="body2">
                I’m open and available for work.
              </Typography>
            </div>
            }
          />
        </Grid>
        <Grid>
          <FormControlLabel
            control={
              <Switch
                checked={availableForProjects}
                onChange={handleAvailableForProjectsChange}
                color="primary"
              />
            }
            label={<div>
              <Typography variant="body1">Email alerts:</Typography>
              <Typography variant="body2">
                Receive email alerts and notifications of activities.
              </Typography>
            </div>
            }
          />
        </Grid>
      </Grid>

      <Grid item xs={12} md={3} sm={4} >
        <Typography variant="body1">{AppText.Privileges}</Typography>
        <Typography variant="body2">{AppText.superAdminUsers.subText3}</Typography>
      </Grid>
      <Grid item xs={12} md={9} sm={8}>
        <Grid>
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={handleCheckboxChange}
                color="primary"
              />
            }
            label={<div>
              <Typography variant="body1">Reports</Typography>
              <Typography variant="body2">
                Receive email alerts and notifications of activities.
              </Typography>
            </div>
            }
          />
        </Grid>
        <Grid>
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={handleCheckboxChange}
                color="primary"
              />
            }
            label={<div>
              <Typography variant="body1">Manage users</Typography>
              <Typography variant="body2">
                View, add and delete user accounts and details
              </Typography>
            </div>
            }
          />
        </Grid>
      </Grid>

      {/* Profile */}
      <Grid item xs={12} md={3} sm={4}>
        <Typography variant="body1">{AppText.superAdminUsers.subTitle2}</Typography>
        <Typography variant="body2">{AppText.superAdminUsers.subTitle2}</Typography>
      </Grid>
      <Grid item xs={12} md={9} sm={8}>
        <Card>
          <CardContent>

            <TextField
              label={AppText.fields.userName}
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              fullWidth
              margin="normal"
              size='small'
            />
            <TextField

              label={AppText.fields.website}
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              fullWidth
              margin="normal"
              size='small'
            >

            </TextField>
            <TextField
              label={AppText.fields.description}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
              margin="normal"
              multiline
              size='small'
            />
            <TextField
              select
              label={AppText.fields.country}
              value={country}
              fullWidth
              onChange={(e) => setCountry(e.target.value)}
              size="small"
              margin="normal"
            >
              {countryOptions.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              select
              label={AppText.fields.timeZone}
              value={timezone}
              onChange={(e) => setTimezone(e.target.value)}
              fullWidth
              size='small'
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccessTimeIcon />
                  </InputAdornment>
                ),
              }}

            />

            <Divider />
            <Grid style={{ marginTop: '20px', textAlign: 'right' }}>
              <Button variant="outlined" onClick={handleCancel}>{AppText.Cancel}</Button>
              <Button variant="contained" onClick={handleSaveChanges} style={{ marginLeft: '10px' }}>{AppText.SaveChanges}</Button>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default SuperAdminUsers;