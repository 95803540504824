import React from 'react';
import { Grid, Typography,IconButton,Box } from '@mui/material';
import MUIDataTable from 'mui-datatables';

import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';


const columns = ["Activity Type", "Access Date and Time", "End Date and Time", "project Id"];

const data = [
  ['Locker', '26-Oct 2023', "26-Oct 2023",'john@example.com'],
  ["Access control", '26-nov 2023','26-nov 2023','jane@example.com'],
  ["Access control", '26-nov 2023','26-nov 2023','jane@example.com'],
  ['Locker', '26-Oct 2023', "26-Oct 2023",'john@example.com'],
  ["Access control", '26-nov 2023','26-nov 2023','jane@example.com'],
  ['Locker', '26-Oct 2023', "26-Oct 2023",'john@example.com'],
  ["Access control", '26-nov 2023','26-nov 2023','jane@example.com'], 
  ['Locker', '26-Oct 2023', "26-Oct 2023",'john@example.com'],
  
];

const options = {
  
  filterType: 'checkbox',
  search: "false",
  print:"false",
  download:"false",
  viewColumns:"false",
  FilterTable: "false",
  selectableRows: "none",
  responsive : "standard",

  filter: 'false',
  customToolbar: () => {
    return (
      <div>
        {/* Your custom toolbar content goes here */}
        <IconButton onClick={() => alert('Custom button clicked')}> <NotificationsNoneIcon />  </IconButton>
        <IconButton onClick={() => alert('Custom button clicked')}> <AccountCircleOutlinedIcon /> </IconButton>
      </div>
    );
  },
};


const Activityhistory = () => {
  return (

 <Box>
    <Grid container>
      
        <Grid item xs={12} md={12} sm={12}>
          
           <Grid item xs={12} sm={12} md={12} mb={5}>
              <Typography variant="h4">User Activity History</Typography>
           </Grid>
         
    
  
  
            <Grid item xs={12} md={12} sm={12}>
               <MUIDataTable title={"Hi, Agathiyan" } 
    
                    data={data}
                    columns={columns}
                    options={options}
                   />
             </Grid>
         </Grid>
    

     </Grid>
   </Box>
  );
};

export default Activityhistory;
