import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SubscriptonOverView from './SubscriptionOverview';
import LicenseAssignment from './LicenseAssignment';
import BuyMoreLicenses from './BuyMoreLincenses';
import { Typography } from '@mui/material';
import { Grid } from '@mui/material';
import AppText from '../../Utils/AppText';

const SubscriptionTabs = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container  >
        <Grid item xs={12} sm={12} md={12}>
        <Typography variant='h5'>{AppText.heading.subscription}</Typography>
        </Grid>
        <Grid item>
      <Tabs value={value} onChange={handleChange} centered>
        <Tab label={AppText.overview} />
        <Tab label={AppText.subscription.licenseAssignment}/>
        <Tab label={AppText.subscription.buyMoreLicenses} />
      </Tabs>
      {value === 0 && <SubscriptonOverView />}
      {value === 1 && <LicenseAssignment />}
      {value === 2 && <BuyMoreLicenses />}
      </Grid>
    </Grid>
  );
};

export default SubscriptionTabs;