import React, { useState ,useEffect} from "react";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import colorConfigs from "../configs/colorConfigs";
import SidebarItem from "./SidebarItem";
import { useLocation } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

const SidebarItemCollapse = ({ item ,showText}) => {
    const [open, setOpen] = useState(false);
    let location = useLocation();
    const [pathName, setPathName] = useState(location.pathname);
    useEffect(() => {
     //console.log('lll', location);
     setPathName(location.pathname)
    }, [location]);
  
    
    return (
        item.sidebarProps ? (
          <>
            <ListItemButton
              onClick={() => setOpen(!open)}
              sx={{
                "&: hover": {
                  backgroundColor: colorConfigs.sidebar.hoverBg
                },
                backgroundColor:  pathName ===item.path ?  colorConfigs.sidebar.activeBg : `none` ,
              color:pathName === item.path ?  colorConfigs.sidebar.color : colorConfigs.colorCode.black,
            
                //paddingY: "12px",
                //paddingX: "24px"
              }}
            >
               <Tooltip title={item.sidebarProps.displayText}  sx={{'& .MuiTooltip-tooltip': {
      fontFamily: 'Inter, sans-serif', // Replace 'YourFontFamily' with the desired font
    },}}>
                <ListItemIcon sx={{
                  color: pathName === item.path ?  colorConfigs.sidebar.color : colorConfigs.colorCode.black,
                  margin: `-5px`
                }}>
                  {item.sidebarProps.icon && item.sidebarProps.icon}
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="caption">
                    {item.sidebarProps.displayText}
                  </Typography>
                }
              />
              {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
            </ListItemButton>
            <Collapse in={open} timeout="auto">
              <List>
                {item.child?.map((route, index) => (
                  route.sidebarProps ? (
                    route.child ? (
                      <SidebarItemCollapse item={route} key={index} />
                    ) : (
                      <SidebarItem item={route} key={index} showText={showText} />
                    )
                  ) : null
                ))}
              </List>
            </Collapse>
          </>
        ) : null
      );
    };
    
export default SidebarItemCollapse;
